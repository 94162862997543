.start-container {
    text-align: center;
    margin-top: 100px;
  }
  
  .start-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .start-container p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .start-buttons .btn {
    padding: 10px 20px;
    margin: 0 10px;
    text-decoration: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #007bff;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-secondary {
    background-color: #6c757d;
  }
  
  .btn-secondary:hover {
    background-color: #545b62;
  }
  