.register-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }
  
  .register-container h1 {
    margin-bottom: 20px;
  }
  
  .register-container form > div {
    margin-bottom: 15px;
  }
  
  .register-container input {
    width: 90%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .register-container .error {
    color: red;
    margin-bottom: 15px;
  }
  
  .register-container button {
    width: 60%;
    padding: 10px;
    border: none;
    background-color: #333;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .register-container button:hover {
    background-color: #555;
  }
  