@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body{
    background-color: #f5f5f5;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #333;
    text-align: left;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.loading-info{
    text-align: center;
    margin-top: 20px;
    font-size: 25px;
}

.error-info{
    text-align: center;
    margin-top: 20px;
    font-size: 25px;
    color: red;
}