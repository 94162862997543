.reset-password-container {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
  }
  
  .reset-password-container h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .reset-password-container form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .reset-password-container input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .reset-password-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .reset-password-container button:hover {
    background-color: #0056b3;
  }
  
  .reset-password-container .message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
  }
  