.logs-container {
    width: 80%;
    margin: 20px auto;
  }
  
  .logs-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #f2f2f2;
  }

  .conversation-sublog {
    text-align: left;
  }
  
  .conversation-expand{
    cursor: pointer;
  }
  
  .conversation-summary td:first-child {
    text-align: center;
  }

  
  .conversation-details .log-entry {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid #eee;
  }

  /*button for saving status*/
  .conversation-status-save{
    width: 80%;
    height: auto;
    min-width: 100px;
    min-height: 20px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
  }
  
  .conversation-details .log-entry:last-child {
    border-bottom: none;
  }

  .conversation-details .log-entry > div {
    /* If you have more than three divs, adjust the width accordingly */
    width: 10%; /* Default width for time and type */
    min-width: 80px;
  }
  
  .conversation-details .log-entry > div:last-child {
    width: 80%; /* Remaining width for message */

  }
  
  .log-type.darkblue {
    color: darkblue;
  }
  
  .log-type.lightblue {
    color: rgb(4, 172, 228);
  }

  .conversation-status-input input{
    width: 80%;
    height: auto;
    min-width: 100px;
    min-height: 20px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  

  .filters {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .filters input[type="date"],
  .filters input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .filters input[type="text"]::placeholder {
    color: #888;
  }
  
  /* Adjust the width of the inputs as needed */
  .filters input[type="date"] {
    max-width: 180px;
  }
  
  .filters input[type="text"] {
    max-width: 200px;
  }
  
  .summary-section {
    margin: 20px 0;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .summary-section:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  
  .summary-section h3 {
    margin: 0;
    padding: 10px;
    cursor: pointer;
    font-size: 1.2em;
    border-radius: 5px;
  }
  
  .summary-section h3:hover {
    background-color: #dee2e6;
  }
  
  .summary-details {
    padding: 10px;
    background-color: white;
    border-top: 1px solid #dee2e6;
    border-radius: 0 0 5px 5px;
  }
  
  .summary-details div {
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 3px;
  }
  
  .summary-details div:last-child {
    margin-bottom: 0;
  }

  .highlighted {
    background-color: lightgreen;
    transition: background-color 0.5s ease;
  }

  .no-display {
    display: none;
  }

  .pagination {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    cursor: pointer;
    color: blue;
    text-decoration: none;
  }

  /* .summary-header {
    display: flex;
  } */

  .channel-summaries-container {
    display: flex;
    /*right side of div*/
    justify-content: flex-end;
    text-align: right;
    font-size: 14px;
    flex-direction: column;
  }
  