.service-settings {
  margin: 0 auto;
  max-width: 800px; /* Adjust the width as needed */
  padding: 20px;
  font-family: Arial, sans-serif;
}

.service-settings h1,
.service-settings h2 {
  text-align: center;
  color: #333;
}

.settings-table,
.settings-table-additional {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.settings-table td,
.settings-table-additional td {
  padding: 8px;
  border: 1px solid #ddd;
  text-align: center;
}

.settings-table-additional td:first-child,
.settings-table td:first-child {
  font-weight: bold;
  text-align: left;
  width: 30%;
}

.settings-table td:nth-child(2),
.settings-table-additional td:nth-child(2) {
  width: 70%;
}

.settings-table-additional input,
.settings-table-additional select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.save-button {
  display: block;
  width: 100%;
  padding: 10px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.save-button:hover {
  background-color: #0056b3;
}

.service-button {
  font-weight: bold;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
}

.service-on {
  background-color: #28a745;
}

.service-off {
  background-color: #dc3545;
}

.settings-updated-info {
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 20px;
  width: 70%;
  font-weight: bold;
  text-align: center;
  margin-left: auto; /* Center the element horizontally */
  margin-right: auto; /* Center the element horizontally */
}

.settings-updated-info.true {
  opacity: 1;
  visibility: visible;
  color: #28a745;
  background-color: #d4edda;
}

.settings-updated-info.false {
  opacity: 1;
  visibility: visible;
  color: #dc3545;
  background-color: #f8d7da;
}

/* Responsive styles */
@media (max-width: 768px) {
  .service-settings {
    padding: 10px;
  }

  .settings-table td,
  .settings-table-additional td {
    font-size: 0.8em; /* Smaller font size for table contents */
  }

  .settings-updated-info {
    width: 90%; /* Adjust width for smaller screens */
    font-size: 16px; /* Smaller font size for the info box */
  }
}

@media (max-width: 480px) {
  .settings-table-additional td:first-child,
  .settings-table td:first-child {
    width: 40%; /* Adjust the width of the first column for smaller screens */
  }

  .settings-table td:nth-child(2),
  .settings-table-additional td:nth-child(2) {
    width: 60%; /* Adjust the width of the second column for smaller screens */
  }

  .settings-updated-info {
    width: 100%; /* Full width for very small screens */
  }
}
