.services-container {
  width: 80%;
  margin: 20px auto;
}

.services-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 8px;
  text-align: center;
}

th {
  background-color: #f2f2f2;
}

button {
  padding: 5px 10px;
  margin-right: 5px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.service-button {
  width: 10%;
  min-width: 130px;
}

.service-button.service-on {
  background-color: #28a745;
  color: white;
}

.service-button.service-off {
  background-color: #dc3545;
  color: white;
}

/* Responsive styles */
@media (max-width: 768px) {
  .services-container {
    width: 95%;
  }

  .service-button {
    min-width: 100px; /* Adjust the min-width for smaller screens */
  }

  table, th, td {
    font-size: 0.9em; /* Smaller font size for table contents */
  }
}

@media (max-width: 480px) {
  .service-button {
    width: auto; /* Allow buttons to take the necessary width */
    min-width: 80px; /* Further reduce the min-width */
  }

  table {
    display: block;
    overflow-x: auto; /* Enable horizontal scrolling for tables */
  }

  th, td {
    white-space: nowrap; /* Prevent text wrapping in table cells */
  }
}
