.navbar {
    background: #0039ca;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 70px;
    width: 100%;
    padding: 0 24px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
  }
  
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-left: 1px solid white;
    border-right: 1px solid white;
  }

  
  .nav-item:hover {
    background-color: #0019ca;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  .navbar-logo-image {
    height: 40px;
    width: auto;
    filter: brightness(0) invert(1);
  }


  