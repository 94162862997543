.changelog-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .changelog-container h1 {
    text-align: center;
  }
  
  .changelog-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .changelog-container li {
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }
  
  .changelog-container li:last-child {
    border-bottom: none;
  }
  