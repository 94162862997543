.login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
  }
  
  .login-container h1 {
    margin-bottom: 20px;
  }
  
  .login-container form > div {
    margin-bottom: 15px;
  }
  
  .login-container input {
    width: 90%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .login-container .error {
    color: red;
    margin-bottom: 15px;
  }
  
  .login-container button {
    width: 60%;
    padding: 10px;
    border: none;
    background-color: #0039ca;
    color: white;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #555;
  }
  
  .forgot-password {
    margin-top: 10px;
    text-align: center;
  }
  
  .forgot-password a {
    color: #007bff;
    text-decoration: none;
  }
  
  .forgot-password a:hover {
    text-decoration: underline;
  }
  